<template>
  <div>
    <van-nav-bar :title="garageName" left-arrow :fixed="true" :z-index="999" @click-left="back" />

    <div style="padding-top:46px;">
      <div v-for="(item,idx) in list" :key="idx">
        <van-row>
          <van-col span="12" class="ls">
            <div class="shu"></div>
            <div class="name">{{item.garageName}}</div>
          </van-col>
          <van-col span="12" class="rs">
            <div class="dd" style="color: #1989fa" @click="gotoxq(item)">详情</div>
            <div
              class="dd2"
              style="color: rgba(69, 90, 100, 0.6)"
              v-if="item.settlementDate"
            >{{item.settlementDate.substring(0,10)}}</div>
          </van-col>
        </van-row>
        <van-cell-group>
          <van-cell title="结算金额" :value="'￥'+ item.totalActiveAmount" />
          <van-cell class="jiner" title="提成金额" :value="'￥'+item.zong" />
        </van-cell-group>
      </div>

      <!-- <div>
        <van-row>
          <van-col span="12" class="ls">
            <div class="shu"></div>
            <div class="name">粤S66666</div>
          </van-col>
          <van-col span="12" class="rs">
            <div class="dd" style="color: #1989fa">详情</div>
            <div class="dd2" style="color: rgba(69, 90, 100, 0.6)">2020-05-20</div>
          </van-col>
        </van-row>
        <van-cell-group>
          <van-cell title="结算金额" value="￥1200" />
          <van-cell class="jiner" title="提成金额" value="￥120" />
        </van-cell-group>
      </div>-->
    </div>
  </div>
</template>

<script>
import userwei from "../../api/user";
export default {
  components: {},
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      list: [],
      value: "",
      gid: "",
      garageName: "",
      zong: null
    };
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    }
  },
  methods: {
    onLoad() {
      // 
      userwei
        .inCome({ customerId: this.userInfo.id, gid: this.gid })
        .then(res => {
          console.log(res);
          this.list = res.data;

          this.list.forEach((itemd, index) => {
            var zonger = null;
            var zonger2 = null;
            var heji = 0;
            var heji2 = 0;
            console.log(itemd);
            var TCpeijian = itemd.tokerPercentageDTO;
            //工时提成
            var percentageTimeRate =
              itemd.tokerPercentageDTO.percentageTimeRate;
            //配件提成
            var percentagePartRate =
              itemd.tokerPercentageDTO.percentagePartRate;
            itemd.orderSettlementedDetailsVOS.orderItemList.forEach(
              (item, index) => {
                zonger += parseFloat(item.standPrice * item.counts);
                heji += parseFloat(
                  item.standPrice * item.counts * (percentageTimeRate / 100)
                );

                item.orderPartList.forEach((items, indexs) => {
                  if (TCpeijian.tokerPartPercentageDOS.length < 1) {
                    items.ticheng = TCpeijian.percentagePartRate;
                  } else {
                    TCpeijian.tokerPartPercentageDOS.forEach((i, indexs) => {
                      if (i.partName == items.partName) {
                        items.ticheng = i.percentage;
                      } else {
                        items.ticheng = TCpeijian.percentagePartRate;
                      }
                    });
                  }
                  zonger2 += parseFloat(items.price * items.counts);
                  heji2 += parseFloat(
                    items.price * items.counts * (items.ticheng / 100)
                  );
                });
              }
            );
            console.log(heji)
              if(itemd.orderSettlementedDetailsVOS.amountDiscount){
              itemd.zong = (heji2 * 1 + heji * 1)-(itemd.orderSettlementedDetailsVOS.amountDiscount*0.15);
            }else{
              itemd.zong = (heji2 * 1 + heji * 1)
            }
          });
        });
    },
    gotoxq(item) {
      // console.log(item)
      this.$router.push({
        name: "tuoke_detail",
        params: {
          list: item
        }
      });
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.gid = this.$route.params.gid;
    this.garageName = this.$route.params.garageName;

    // console.log(this.$route.params.gid);
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
div {
  color: #323233;
}
.views {
  /deep/ div:nth-of-type(2) span {
    color: #1989fa;
  }
}
.sumit {
  width: 100%;
  padding: 10px;
  position: absolute;
  bottom: 0;
}
.van-button {
  width: 50%;
}
.van-row {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #ebedf0;
  overflow: hidden;
  font-size: 13px;
}
.ls {
  padding-left: 10px;
}
.shu {
  float: left;
  height: 29px;
  margin-top: 8px;
  width: 5px;
  background: #4fc08d;
}
.name {
  margin-left: 10px;
  float: left;
  // display: inline-block;
}
.rs {
  height: 45px;
  overflow: hidden;
  text-align: right;
  padding-right: 20px;
}
.dd {
  // display: inline-block;
  float: right;
}
.dd2 {
  float: right;
  margin-right: 30px;
}
// .van-cell-group .van-cell__value:nth-child(2){
//     color: #ee0a24;
// }
.jiner .van-cell__value {
  color: #ee0a24;
}
</style>
